import * as actionTypes from './actiontypes'
import getAdminInitialState from './states'
import { LOCAL_STORAGE } from '../../constants/common'

const admin_details = (state = getAdminInitialState().admin_details, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ADMIN_STATE: {
      return {
        ...state,
        ...action.payload
      }
    }

    case actionTypes.UPDATE_USER_INFO: {
      const oriAdminDetails = {
        nlpServiceDetails: state.nlpServiceDetails,
        admin_id: state.admin_id,
        token: state.token,
        allowed_channels: state.allowed_channels,
        brandInfo: state.brandInfo,
        brandData: state.brandData,
        user_info: {
          ...state.user_info,
          ...action.payload
        },
        _id: state._id,
      }
      localStorage.setItem(LOCAL_STORAGE.ORI_ADMIN, JSON.stringify(oriAdminDetails))
      return {
        ...state,
        user_info: {
          ...state.user_info,
          ...action.payload
        },
      }
    }

    default:
      return state
  }
}

export default admin_details
