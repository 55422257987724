import { getCookies, getDataFromLocalStorage } from "../../utils/dataUtility"
import { COOKIES, LOCAL_STORAGE } from "../../constants/common"

const getAdminInitialState = () => {
  const token= getCookies(COOKIES.TOKEN, null)
  const oriAdminDetails = getDataFromLocalStorage(LOCAL_STORAGE.ORI_ADMIN, null)
  const bifurcation_data = getDataFromLocalStorage(LOCAL_STORAGE.BIFURCATION_DATA, {
    selected: {},
    filters: []
  })
  const states = {
    admin_details: {
      _id: oriAdminDetails && oriAdminDetails._id ? oriAdminDetails._id : null,
      nlpServiceDetails: oriAdminDetails && oriAdminDetails.nlpServiceDetails ? oriAdminDetails.nlpServiceDetails : null,
      allowed_channels: oriAdminDetails && oriAdminDetails.allowed_channels ? oriAdminDetails.allowed_channels : [],
      brandInfo: oriAdminDetails && oriAdminDetails.brandInfo ? oriAdminDetails.brandInfo : null,
      brandData: oriAdminDetails?.brandData ? oriAdminDetails?.brandData: null,
      admin_id: oriAdminDetails && oriAdminDetails.admin_id ? oriAdminDetails.admin_id : null,
      token,
      user_info: oriAdminDetails && oriAdminDetails.user_info ? oriAdminDetails.user_info : {},
      bifurcation_data,
      bifurcation_loading: false,
      bifurcation_disable: false,
      auth_loading: false,
      token_verifying: false,
      token_verified: false,
    }
  }
  return states
}

export default getAdminInitialState
