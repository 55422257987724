export const CHAT_STATE = {
  PENDING: "Pending",
  AGENT_HANDLING: "AgentHandling",
  BOT_HANDLING: "BotHandling",
  IN_QUEUE: "Inqueue",
}

export const TICKET_STATE = {
  ASSIGNED: "Assigned",
  IN_QUEUE: "Inqueue",
}

export const CHAT_TAB = {
  LIVE_CHATS: "live-chats",
  TICKETS: "tickets"
}

export const TICKET_LIST_TYPE = {
  MY_TICKETS: "my tickets",
  AGENT_TICKETS: "agent tickets",
  IN_QUEUE: "in queue"
}

export const CHAT_LIST_TYPE = {
  PENDING: "pending",
  IN_QUEUE: "in queue",
  MY_CHAT: "my chats",
  AGENT_CHATS: "agent chats",
  SUPERVISED: "supervised",
  BOT_HANDLING: "bot handling",
  UNTAGGED_CHATS: "untagged chats",
  PENDING_INVITE: "pending invite",
  INVITE_CHAT: "invite chats"
}

export const INVITE_STATE = {
  PENDING_INVITE: "pendingInvite",
  INVITE_CHAT: "inviteChats"
}

export const MESSAGE_TYPES = {
  TEXT: "text",
  SYSTEM_TEXT: "systemText",
  TEXT_WITH_BUTTONS: "text_with_buttons",
  IMAGE_WITH_BUTTONS: "image_with_buttons",
  VIDEO: "video",
  TIMER: "timer",
  CAROUSEL: "carousel",
  CUSTOM_MSG: "customMsg",
  FORM: "form",
  LINK: "link",
  QUICK_RESPONSE: "quick_response",
  WHISPER: "whisper",
  UPLOADED_DOCUMENT: "uploadedDocument",
  ATTACH_DOCUMENT: "document"
}

export const MESSAGE_TYPE_OPTIONS = [
  { label: "Text", value: MESSAGE_TYPES.TEXT },
  { label: "Text with buttons", value: MESSAGE_TYPES.TEXT_WITH_BUTTONS },
  { label: "Image with buttons", value: MESSAGE_TYPES.IMAGE_WITH_BUTTONS },
  { label: "Video", value: MESSAGE_TYPES.VIDEO },
  { label: "Carousel", value: MESSAGE_TYPES.CAROUSEL },
]

export const MESSAGE_SUBTYPES = {
  DISH_RECHARGE: "dishRecharge",
  DISH_RECHARGE_DETAILS: "dishRechargeDetails",
  DISH_OFFERS: "dishOffers",
  DISH_RECHARGE_HISTORY: "dishRechargeHistory",
}

export const MESSAGE_SENDER = {
  CUSTOMER: "customer",
  CHATBOT: "chatbot",
  ADMIN: "admin",
  SYSTEM: "system",
}

export const MESSAGE_READ_STATUS = {
  SENT: "sent",
  DELIVERED: "delivered",
  SEEN: "seen",
  FAILED: "failed",
}

export const BUTTON_TYPES = {
  LINK: "link",
  DEFAULT: "default",
  CUSTOM: "custom",
  CUSTOM_SOCKET_EVENT: "customSocketEvent",
  POST_BACK_RESPONSE: "postbackRes",
}

export const BUTTON_TYPE_OPTIONS = [
  { label: "Default", value: BUTTON_TYPES.DEFAULT },
  { label: "Link", value: BUTTON_TYPES.LINK },
  { label: "Custom socket event", value: BUTTON_TYPES.CUSTOM_SOCKET_EVENT },
]

export const DMS_ACTION_TYPE = {
  MESSAGE: "message",
  BRANDFUNCTION: "brandFunction",
  WORKFLOW: "workflow"
}

export const DMS_ACTION_TYPE_OPTIONS = [
  { label: "Message", value: DMS_ACTION_TYPE.MESSAGE },
  { label: "Brand Function", value: DMS_ACTION_TYPE.BRANDFUNCTION },
  { label: "Workflow", value: DMS_ACTION_TYPE.WORKFLOW },
]

export const AGENT_STATUS = {
  ACCEPTING: "accept chats",
  NOT_ACCEPTING: "don't accept chats",
}

export const BLOCKED_STATUS = {
  PENDING: "pending",
  REJECTED: "rejected",
  BLOCKED: "blocked",
  UNBLOCKED: "unblocked",
}

export const PROFILE_ITEM_TYPE = {
  DATE: "date",
  CURRENCY: "currency",
  DATE_TIME: "datetime",
  URL: "url",
}

export const BOT_EVENT_SOURCE = {
  CALL: "call",
  CHAT: "chat",
  EMAIL: "email",
}

export const REVIEW_STATUS = {
  PENDING: "pending",
  COMPLETE: "complete",
}

export const INPUT_COMPOSER_TYPE = {
  BARGE: "barge",
  WHISPER: "whisper",
}

export const TEXT_TYPE = {
  USER_TYPED: "userTyped",
  CANNED_RESPONSE: "cannedResponse",
}

export const ROLES = {
  ORI_ADMIN: "oriAdmin",
  ADMIN: "Admin",
  SUPERVISOR: "Supervisor",
  AGENT: "Agent",
  QA: "QA",
  TRAINER: "Trainer",
  MANAGER: "Manager",
  ASSISTANT_MANAGER: "AssistantManager",
  SUPER_ADMIN: "Superadmin"
}

export const ROLE_OPTIONS = [
  { name: "Admin", value: ROLES.ADMIN },
  { name: "Agent", value: ROLES.AGENT },
  { name: "Supervisor", value: ROLES.SUPERVISOR },
  { name: "QA", value: ROLES.QA },
  { name: "Trainer", value: ROLES.TRAINER },
  { name: "Manager", value: ROLES.MANAGER },
  { name: "Assistant Manager", value: ROLES.ASSISTANT_MANAGER },
]

export const AGENT_INVITATION_WITH = {
  EMAIL: "email",
  USER_NAME: "userName",
}

export const CHAT_TRANSFER_TO = {
  AGENT: "agent",
  GROUP: "group",
}

export const NOTIFICATION_TYPE = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  OPEN: "open",
}